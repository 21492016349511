import React, {createContext, useState} from "react";
import {Outlet} from "react-router-dom";
import {Container, Stack, Box} from "@mui/material";
import {TextField} from "@mui/material";

export const TextStringContext = createContext("");

export default function WordCountBase(){

    const [TextString,setTestString] = useState("");

    function onChange(e: any){
        setTestString(e.currentTarget.value);
    }

    return (
        <>
            <Container className="main-container">
                <Stack spacing={3}>
                    <TextStringContext.Provider value={TextString}>
                        <Outlet />
                    </TextStringContext.Provider>

                    <TextField
                        multiline rows={20}
                        onChange={event => onChange(event)}/>
                </Stack>
            </Container>
        </>
    )
}

