import React from 'react';
import { Button, TextField, Stack } from '@mui/material';
import {ToastOptions} from "react-toastify/dist/types";
import {Bounce, toast} from "react-toastify";

// Icon
import AddIcon from '@mui/icons-material/Add';

interface ItemInputProps {
    onAdd: (itemName: string) => void;
}

export const ItemInput: React.FC<ItemInputProps> = ({ onAdd }) => {
    const [itemName, setItemName] = React.useState('');

    const toastOption: ToastOptions = {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
    }


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setItemName(event.target.value);
    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') addItem()
    };

    const addItem = () => {
        if (!itemName){
            toast.error('빈 항목은 추가할 수 없습니다', toastOption);
            return;
        }
        onAdd(itemName);
        setItemName('');
    };

    return (
        <Stack style={{width: "100%"}} spacing={2}>
            <TextField
                label="추첨 항목을 입력해주세요"
                value={itemName}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                placeholder="Ex) 저녁으로 치킨먹기"
                fullWidth
            />
            <Button variant="contained" onClick={addItem} startIcon={<AddIcon />}>
                추첨 항목 추가하기
            </Button>
        </Stack>
    );
};
