import React from 'react';
import { toast, Bounce } from "react-toastify";
import {ToastOptions} from "react-toastify/dist/types";

export const useRandomItems = () => {
    const [items, setItems] = React.useState<string[]>([]);

    const addItem = (itemName: string, silent: boolean = false) => {
        setItems(prev => [...prev, itemName]);
        if (!silent) toast.info(`${itemName} 항목을 추가하였습니다!`, toastConfig);
    };

    const deleteItem = (index: number, silent: boolean = false) => {
        const deletedItem = items[index];
        setItems(items.filter((_, i) => i !== index));
        if (!silent) toast.info(`${deletedItem} 항목을 삭제하였습니다!`, toastConfig);
    };

    const clearItems = (silent: boolean = false) => {
        setItems([]);
        if (!silent) toast.success('항목을 초기화하였습니다!', toastConfig);
    };

    return { items, addItem, deleteItem, clearItems };
};

const toastConfig:ToastOptions = {
    position: "bottom-right",
    autoClose: 1000,
    closeOnClick: true,
    draggable: true,
    theme: "light",
    transition: Bounce,
};