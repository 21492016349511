import React from 'react';
import {Container, Box, Stack, Grid2, Typography} from '@mui/material';
import {RadioGroup, Radio, FormControlLabel} from "@mui/material";

// components
import { ResistanceColorSelector } from "../components/resistanceColorSelector";
import CardBox from "../components/CardBox";

// style
import styles from "../style/ResistanceCalculator.module.css";


export default function ResistanceCalculatorPage(){
    const [bandValue, setBandValue] = React.useState('4');
    const [bandColorNumbers, setBandColorNumbers] = React.useState([0, 0, 0]);
    const [toleranceColorNumber, setToleranceColorNumber] = React.useState(0);
    const [calculationResult, setCalculationResult] = React.useState('저항값: 0Ω ± 0%');
    const [resetSignal, setResetSignal] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.value === '4') setBandColorNumbers([0, 0, 0]);
        else if(event.target.value === '5') setBandColorNumbers([0, 0, 0, 0]);
        setToleranceColorNumber(0);

        setCalculationResult('저항값: 0Ω ± 0%');
        setResetSignal(prev => !prev);
        setBandValue(event.target.value);
    };

    const setBandColorNumber = (index: number, colorIndex: number) => {
        const value = parseInt(bandValue);
        const newBandColorNumbers = [...bandColorNumbers];
        let newToleranceColorNumber = toleranceColorNumber;

        colorIndex += 1;
        if (index === value - 1) {
            newToleranceColorNumber = colorIndex;
            setToleranceColorNumber(colorIndex);
        } else {
            newBandColorNumbers[index] = colorIndex;
            setBandColorNumbers(newBandColorNumbers);
        }

        // value가 4일떄와 5일때 범용적으로 처리되도록 만들기
        // 각 항목이 0이 아닌지 확인
        let isAllSelected = true;
        for(let i = 0; i < newBandColorNumbers.length; i++){
            if(newBandColorNumbers[i] === 0){
                isAllSelected = false;
                break;
            }
        }
        if(newToleranceColorNumber !== 0 && isAllSelected){
            calculateResistance(value, newBandColorNumbers, newToleranceColorNumber);
        }
    }

    const calculateResistance = (value: number, bandColorNumbers: number[], toleranceColorNumber: number) => {
        let resistance = 0;
        for(let i = 0; i < bandColorNumbers.length-1; i++) resistance = resistance * 10 + (bandColorNumbers[i] - 1);
        resistance *= Math.pow(10, bandColorNumbers[bandColorNumbers.length-1] - 1);
        if(resistance >= 1000){
            if(resistance % 1000 === 0) setCalculationResult(`저항값: ${(resistance/1000)}kΩ ± ${tolerancePercent[toleranceColorNumber]}%`);
            else setCalculationResult(`저항값: ${(resistance/1000)}kΩ (${resistance}Ω) ± ${tolerancePercent[toleranceColorNumber]}%`);
        }
        else setCalculationResult(`저항값: ${resistance}Ω ± ${tolerancePercent[toleranceColorNumber]}%`);
    }

    const bandColors = [
        { color: '#D3D3D3', name: '미선택' },
        { color: '#000000', name: '검정색 (Black)' },
        { color: '#A52A2A', name: '갈색 (Brown)' },
        { color: '#FF0000', name: '빨간색 (Red)' },
        { color: '#FFA500', name: '주황색 (Orange)' },
        { color: '#FFFF00', name: '노란색 (Yellow)' },
        { color: '#008000', name: '녹색 (Green)' },
        { color: '#0000FF', name: '파란색 (Blue)' },
        { color: '#EE82EE', name: '보라색 (Violet)' },
        { color: '#808080', name: '회색 (Gray)' },
        { color: '#FFFFFF', name: '흰색 (White)' },
    ];

    const toleranceColors = [
        { color: '#D3D3D3', name: '미선택' },
        { color: '#A52A2A', name: '갈색 (Brown)' },
        { color: '#FF0000', name: '빨간색 (Red)' },
        { color: '#008000', name: '녹색 (Green)' },
        { color: '#0000FF', name: '파란색 (Blue)' },
        { color: '#EE82EE', name: '보라색 (Violet)' },
        { color: '#808080', name: '회색 (Gray)' },
        { color: '#FFD700', name: '금색 (Gold)' },
        { color: '#C0C0C0', name: '은색 (Silver)' },
    ];
    
    const tolerancePercent = [0, 1, 2, 0.5, 0.25, 0.1, 0.05, 5, 10];


    return (
        <>
            <Container className="main-container">
                <Stack className="wd-100" spacing={3}>
                    <Grid2 container
                           spacing={{xs: 3, md: 2}}
                           columns={{xs: 4, sm: 4, md: 12}}>
                        <Grid2 size={4}>
                            <CardBox className="flex-center">
                                <Stack className="flex-center" spacing={1}>
                                    <Typography variant="h4" textAlign="center">저항 색띠 계산기</Typography>
                                    <Typography variant="body1" textAlign="center">저항 색띠를 쉽게 읽어보세요!</Typography>
                                </Stack>
                            </CardBox>
                        </Grid2>
                        <Grid2 size={8}>
                            <CardBox className="flex-center">
                                <Stack className="flex-center" spacing={1}>
                                <Typography variant="h4" textAlign="center">저항의 색띠 개수</Typography>
                                    <RadioGroup onChange={handleChange} row value={bandValue}>
                                        <FormControlLabel value="4" control={<Radio/>} label="4개 (4밴드, 갈색)"/>
                                        <FormControlLabel value="5" control={<Radio/>} label="5개 (5밴드, 파란색)"/>
                                    </RadioGroup>
                                    </Stack>
                            </CardBox>
                        </Grid2>
                    </Grid2>

                    <Box className={styles.resistorPage}>
                        <Typography variant="h3" textAlign="center">{calculationResult}</Typography>
                        <Box className={styles.resistorContainer}>
                            <Box className={`${styles.resistorLeads} ${styles.leadLeft}`} />
                            <Box className={styles.resistorBody}>
                                {bandColorNumbers.map((bandIndex, index) => (
                                    <Box
                                        key={index}
                                        className={styles.resistorBand}
                                        style={{ backgroundColor: bandColors[bandIndex].color, left: `${(index + 1) * (100 / (parseInt(bandValue) + 1))}%` }}
                                    />
                                ))}
                                <Box
                                    key={bandColorNumbers.length}
                                    className={styles.resistorBand}
                                    style={{ backgroundColor: toleranceColors[toleranceColorNumber].color, left: `${(bandColorNumbers.length + 1) * (100 / (parseInt(bandValue) + 1))}%` }}
                                />
                            </Box>
                            <Box className={`${styles.resistorLeads} ${styles.leadRight}`} />
                        </Box>
                    </Box>

                    {/* 저항 색 설정 UI */}
                    <Typography variant="h3" fontWeight="bold" textAlign="center">- 저항 색상 -</Typography>
                    <Grid2 container
                           columns={{xs: 4, sm: 8, md: 12}}
                           columnSpacing={{xs: 1, sm: 2, md: 3}}
                           rowSpacing={2}>
                        {Array.from({length: parseInt(bandValue) - 1}, (_, index) => (
                            <ResistanceColorSelector key={index} resetSignal={resetSignal} bandSize={parseInt(bandValue)} bandIndex={index} bandColors={bandColors} selectedColor={setBandColorNumber} />
                        ))}
                        <ResistanceColorSelector key={parseInt(bandValue) - 1} resetSignal={resetSignal} bandSize={parseInt(bandValue)} bandIndex={parseInt(bandValue) - 1} bandColors={toleranceColors} selectedColor={setBandColorNumber}  />
                    </Grid2>
                </Stack>
            </Container>
        </>
    );
}