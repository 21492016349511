import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import {Button, Container, Divider, Stack, Typography} from '@mui/material';
import {TextField} from "@mui/material";

// Toast
import {Bounce, toast} from "react-toastify";
import {ToastOptions} from "react-toastify/dist/types";

// Clarity
import {clarity} from "clarity-js";

// components
import CardBox from "../../components/CardBox";
import {Loading} from "../../components/Loading";

const toastOption: ToastOptions = {
    position: "top-right",
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    transition: Bounce,
}

export default function ErrorReportPage() {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [description, setDescription] = useState('');
    const [contact, setContact] = useState('');
    const [clarityActive, setClarityActive] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        clarity.metadata((_) => {
            console.log("Clarity is active");
            setClarityActive(true);
        });
    }, []);

    const handleSubmit = () => {
        if(description === '') {
            toast.error('오류 내용은 작성해주셔야해요...ㅠㅠ', toastOption);
            return;
        } else if(description.length < 10) {
            toast.error('오류를  더 자세히 작성해주세요.', toastOption);
            return;
        }
        setLoading(true);

        // Clarity Tagging
        clarity.set('error-report', 'submit');
        clarity.set('error-report-description', description);
        clarity.set('error-report-contact', contact);

        if(clarityActive) {
            clarity.metadata((metadata) => {
                submitErrorReport(metadata);
            });
        } else {
            submitErrorReport({userId: 'unknown', sessionId: 'unknown'});
        }
    };

    const submitErrorReport = (metadata: {userId: string, sessionId:string}) => {
        try {
            const formData = JSON.stringify({
                description: description,
                contact: contact,
                userAgent: navigator.userAgent,
                userID: metadata.userId,
                sessionID: metadata.sessionId,
            });
            fetch('https://submit-form.com/A98AQKOvB', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: formData
            }).then(response => {
                setLoading(false);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                toast.success(<>오류 신고가 완료되었습니다!<br />최대한 빨리 수정해볼게요!</>, toastOption);
                navigate('/');
            }).catch(error => {
                setLoading(false);
                console.error(error);
                toast.error(<>오류 신고 중 문제가 발생했습니다!<br />잠시 후 다시 시도해주세요.</>, toastOption);
            });
        } catch (error) {
            setLoading(false);
            console.error(error);
            toast.error('제안 전송 중 문제가 발생했습니다!', toastOption);
        }
    };

    return (
        <Container className="main-container">
            <Loading open={loading} status={"전송중 입니다...."} subStatus={"잠시만 기다려주세요."}/>
            <CardBox>
                <Stack spacing={1}>
                    <Typography variant="h3">🚨 오류 신고</Typography>
                    <Typography variant="body1">
                        혹시 사용하시다가 오류를 발견하셨나요?<br/>
                        아래 양식을 작성해주시면 빠르게 수정하도록 하겠습니다!
                    </Typography>
                    <Divider> Step 1 (필수) </Divider>
                    <Typography variant="h4">🤔 어떤 오류인가요?</Typography>
                    <Typography variant="body1">자세히 적어주실수록 빠르게 수정할 수 있어요!</Typography>
                    <TextField
                        multiline rows={4}
                        placeholder="Ex. 글자수 세기에서 글자수가 안세어져요!"
                        onChange={(e) => setDescription(e.target.value)}/>
                    <Divider> Step 2 (선택) </Divider>
                    <Typography variant="h4">✉️ 연락드릴 수 있는 이메일을 알려주세요!</Typography>
                    <Typography variant="body1">필수는 아니에요!<br/>다만, 작성해주신다면 검토 후 연락드릴게요!</Typography>
                    <TextField
                        placeholder="Ex. contact@makerksh.com"
                        onChange={(e) => setContact(e.target.value)}
                        fullWidth/>
                    <Divider> Step 3 (전송) </Divider>
                    <Typography variant="h4">📤 신고 전송하기</Typography>
                    <Typography variant="body1">
                        위에 작성한 내용을 개발자에게 전송할게요!
                    </Typography>
                    <Button  variant="contained" color="primary" onClick={handleSubmit} fullWidth>
                        신고하기
                    </Button>
                </Stack>
            </CardBox>
        </Container>
    );
}