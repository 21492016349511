import ReactDOM from 'react-dom/client';
import { ThemeProvider, CssBaseline } from '@mui/material';
import ServiceAppRouter from "./AppRouter";
import defaultTheme from "./theme/defaultTheme";
import {clarity} from "clarity-js";
import './index.css';

clarity.start({
    projectId: process.env.REACT_APP_CLARITY_PROJECT_ID as string,
    upload: 'https://m.clarity.ms/collect',
    track: true,
    content: true,
});
clarity.set('environment', process.env.REACT_APP_ENV as string);
clarity.consent();


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <ServiceAppRouter />
    </ThemeProvider>
);

// TODO : Test Code 추가