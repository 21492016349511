import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Stack, Grid2} from "@mui/material";
import {Typography, Button, TextField} from "@mui/material";

// Base
import {TextStringContext} from "./WordCountBase";

// Icon
import CachedIcon from '@mui/icons-material/Cached';
import CardBox from "../../components/CardBox";


export default function WordCountAdvanced(){
    const [regEx,setTestString] = useState(new RegExp("[]","g"));
    const [isRegExError,setRegExError] = useState(false);

    const navigate = useNavigate();
    const navigateToNomalMode = () => {
        navigate('/wordcount/nomal');
    };

    const TextString = useContext(TextStringContext);
    const calculateTextSizeWithoutRE = () => {
        if(isRegExError) return "?";
        return TextString.replace(regEx, "").length;
    }
    const CountREText = () => {
        if(isRegExError) return "?";
        return TextString.match(regEx)?.length ?? 0;
    }

    const changeRegexInput = (event: any) => {
        const RegexInput: string = event.currentTarget.value === "" ? "[]" : event.currentTarget.value;
        try{
            const re: RegExp = new RegExp(RegexInput, "g");
            setTestString(re);
            setRegExError(false);
        } catch (e) {
            setRegExError(true);
        }
    }

    return (
        <>
            <Grid2 container
                  spacing={{ xs: 3, md: 2 }}
                  columns={{ xs: 4, sm: 4, md: 12 }}>
                <Grid2 size={4}>
                    <CardBox className="flex-center">
                        <Grid2 container flexDirection="row" columns={3} spacing={1}>
                            <Grid2 size={2}>
                                <Stack className="flex-center" spacing={1}>
                                    <Typography variant="h4" textAlign="center">글자수 세기</Typography>
                                    <Typography variant="body1" textAlign="center">[ Advanced Mode ]</Typography>
                                </Stack>
                            </Grid2>
                            <Grid2 size={1}>
                                <Stack className="flex-center" spacing={1}>
                                    <Button variant="contained" startIcon={<CachedIcon />} onClick={navigateToNomalMode}>
                                        Mode Change
                                    </Button>
                                </Stack>
                            </Grid2>
                        </Grid2>
                    </CardBox>
                </Grid2>
                <Grid2 size={4}>
                    <CardBox className="flex-center">
                        <TextField
                            label="정규식 입력"
                            placeholder="Ex) [01]"
                            error={isRegExError}
                            helperText={isRegExError ? "정규식에 오류가 있습니다." : ""}
                            onChange={event => changeRegexInput(event)}
                            fullWidth/>
                    </CardBox>
                </Grid2>
                <Grid2 size={4}>
                    <CardBox className="flex-center">
                        <Stack spacing={1}>
                            <Typography variant="h6" fontWeight="bold" textAlign="center">정규식 제외 결과 : {calculateTextSizeWithoutRE()}자</Typography>
                            <Typography variant="h6" fontWeight="bold" textAlign="center">정규식 검색 개수 : {CountREText()}자</Typography>
                        </Stack>
                    </CardBox>
                </Grid2>
            </Grid2>
        </>
    )
}
