import React from 'react';
import {Stack, Container, Grid2 } from '@mui/material';
import {Typography, Button} from '@mui/material';
import {FormControl, InputLabel, MenuItem} from '@mui/material';
import {Select, SelectChangeEvent} from '@mui/material';

// Toast
import {Bounce, toast} from "react-toastify";
import {ToastOptions} from "react-toastify/dist/types";

// components
import {StandardModal} from "./standardModal";

// Icon
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from '@mui/icons-material/Delete'

interface RandomPickLoadProps {
    open: boolean;
    onClose: () => void;
    addItem: (itemName: string, silent: boolean) => void;
    clearItems: (silent: boolean) => void;
    loadDataList: string[];
}

const toastOption: ToastOptions = {
    position: "top-right",
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    transition: Bounce,
}

export const RandomPickLoadModal: React.FC<RandomPickLoadProps> = ({ open, onClose, addItem, clearItems, loadDataList }) => {
    const [selectDataName, setSelectDataName] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setSelectDataName(event.target.value as string);
    };

    const loadData = () => {
        const loadData = JSON.parse(localStorage.getItem(selectDataName) as string);
        if (selectDataName === '') {
            toast.error('불러올 항목을 선택해주세요!', toastOption);
            return;
        } else if (loadData === null) {
            toast.error('해당 이름의 저장된 항목이 없습니다!', toastOption);
            return;
        }

        // Clear current items
        clearItems(true);

        // Load items
        loadData.saveItems.forEach((item: string) => addItem(item, true));

        // Close modal
        toast.info('불러오기 완료!', toastOption);
        setSelectDataName('');
        onClose();
    }

    const deleteData = () => {
        if (selectDataName === '') {
            toast.error('삭제할 항목을 선택해주세요!', toastOption);
            return;
        }

        localStorage.removeItem(selectDataName);
        toast.info('삭제되었습니다!', toastOption);
        setSelectDataName('');
        onClose();
    }
    const modalContent = (
        <>
            <Container className="flex-center" sx={{minHeight: "10vh"}}>
                <Stack style={{width: "100%"}} spacing={2}>
                    <Stack>
                        <Typography variant="h6" textAlign="center">불러올 항목을 선택해주세요!</Typography>
                        {/* PC UI */}
                        <Typography variant="body1" textAlign="center" color="#ef5350"
                                    display={{ xs: 'none', sm: 'none', md: 'inline'}}>
                            추첨 항목을 불러오는 경우, 현재 작성되어있는 항목은 삭제됩니다!
                        </Typography>
                        {/* Tablet, Mobile UI */}
                        <Typography variant="body1" textAlign="center" color="#ef5350"
                                    display={{xs: 'inline', sm: 'inline', md: 'none'}}>
                            추첨 항목을 불러오는 경우,<br/> 현재 작성되어있는 항목은 삭제됩니다!
                        </Typography>
                    </Stack>

                    <FormControl fullWidth>
                        <InputLabel id="ldata-select-label">과거 항목 선택</InputLabel>
                        <Select
                            labelId="data-select-label"
                            value={selectDataName}
                            onChange={handleChange}
                            label="과거 항목 선택">
                            {loadDataList.length === 0 &&
                                <MenuItem disabled value="">저장된 항목이 없습니다. </MenuItem>
                            }
                            {loadDataList.map((name, index) => (
                                <MenuItem key={index} value={name}>{name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Grid2 container
                           spacing={{ xs: 1, md: 2 }}
                           columns={8}
                           flexDirection={{ xs: 'column', sm: 'row', md: 'row' }}>
                        <Grid2 size={{xs: 8, sm: 4}}>
                            <Button
                                variant="contained" color="error" fullWidth
                                onClick={deleteData} startIcon={<DeleteIcon/>}
                                disabled={selectDataName === ''}>
                                삭제하기
                            </Button>
                        </Grid2>
                        <Grid2 size={{xs: 8, sm: 4}}>
                            <Button
                                variant="contained" color="success" fullWidth
                                onClick={loadData} startIcon={<ReplayIcon/>}
                                disabled={selectDataName === ''}>
                                불러오기
                            </Button>
                        </Grid2>
                    </Grid2>
                </Stack>
            </Container>
        </>
    )

    return (
        <>
            <StandardModal
                open={open}
                onClose={onClose}
                title={"불러오기"}
                content={modalContent}
            />
        </>
    );
}