import * as React from 'react';
import {useNavigate} from "react-router-dom";
import {Box, Toolbar, IconButton, Typography, Container, Avatar, Button, Divider} from "@mui/material";
import {AppBar, Drawer, List, ListItem, ListItemButton, ListItemText} from "@mui/material";

// Icon, image
import MenuIcon from '@mui/icons-material/Menu';
import LogoImage from '../img/logo.jpeg';

// 페이지 목록
const pages:string[] = ['홈', '글자수 세기', '랜덤선택', '저항 색띠 계산', '정규식 생성'];
const paths:string[] = ['/', '/wordcount', '/random', '/resistance', '/regex'];
const ServiceName:string = "Utils";

function ResponsiveAppBar():JSX.Element {
    const [anchorElNav, setAnchorElNav] = React.useState<boolean>(false);

    const navigate = useNavigate();
    const navigatePage = (index: number) => {
        navigate(paths[index]);
    };

    const toggleDrawer =
        (state: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setAnchorElNav(state);
            };

    const list = (
        <Box
            sx={{ width: 250 }}
            role={"presentation"}
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {pages.map((page, index) => (
                    <ListItem key={page} disablePadding>
                        <ListItemButton onClick={() => navigatePage(index)}>
                            <ListItemText primary={page} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </Box>
    );

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* PC 화면 */}
                    <Box onClick={() => navigatePage(0)} sx={{cursor: 'pointer',}}>
                        <Avatar sx={{ display: { xs: 'none', md: 'flex' }, mr: 2 }} alt="Logo Image" src={ LogoImage }  />
                    </Box>
                    <Box onClick={() => navigatePage(0)} sx={{cursor: 'pointer',}}>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            sx={{
                                mr: 2,
                                fontWeight: 700,
                                display: { xs: 'none', md: 'flex' },
                                color: 'inherit',
                                textDecoration: 'none',
                            }}>
                            {ServiceName}
                        </Typography>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page, index) => (
                            <Button
                                key={page}
                                onClick={() => navigatePage(index)}
                                sx={{ my: 2, color: 'white', display: 'inline' }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    {/* 모바일 화면 */}
                    <Box onClick={() => navigatePage(0)}>
                        <Avatar sx={{ display: { xs: 'block', md: 'none' }, mr: 2 }} alt="Logo Image" src={ LogoImage }  />
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        onClick={() => navigatePage(0)}
                        sx={{
                            mr: 2,
                            fontWeight: 700,
                            display: { xs: 'block', md: 'none' },
                            color: 'inherit',
                            textDecoration: 'none',
                            flexGrow: 1,
                        }}>
                        {ServiceName}
                    </Typography>
                    <Box sx={{ flexGrow: 0, display: { xs: 'block', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={toggleDrawer(true)}
                            color="inherit">
                            <MenuIcon/>
                        </IconButton>
                        <Drawer
                            anchor='right'
                            open={anchorElNav}
                            onClose={toggleDrawer(false)}>
                            {list}
                        </Drawer>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
