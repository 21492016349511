import React, {useEffect, useState} from "react";
import { Box, Container, Stack } from "@mui/material";
import { Card, CardHeader, CardContent } from "@mui/material";
import { Collapse, IconButton } from "@mui/material";
import { Typography } from "@mui/material";

// image, css
import BackgroundImage from "../img/background_nomal.png";
import styles from "../style/App.module.css";

// icon
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export  default function UpdateNotePage(){

    const [expanded, setExpanded] = useState<boolean[]>([]);

    const handleExpandClick = (cardID: number) => {
        setExpanded((prev) => {
            const newExpanded = [...prev];
            newExpanded[cardID] = !newExpanded[cardID];
            return newExpanded;
        });
    };

    const updateNote = [
        {
            data: "2023/01/01",
            title:"It is title 1",
            context:(<div>Here are context 1</div>),
        },
        {
            data: "2023/01/01",
            title:"It is title 2",
            context:(<div>Here are context 2</div>),
        }
    ]

    useEffect(() => {
        setExpanded(Array(updateNote.length).fill(false));
    }, [updateNote.length]);

    return (
        <>
            <Stack className="wd-100">
                <Box style={{backgroundColor: "#7f7f7f"}}>
                    <Box className={styles.banner} style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(${BackgroundImage})`}}>
                        <Box display="flex"
                             justifyContent="center"
                             alignItems="center"
                             minHeight="100%">
                            <Typography variant="h1">MakerKSH's Utility Center</Typography>
                        </Box>
                    </Box>
                </Box>
                <Container className="main-container">
                    <Stack spacing={1}>
                        {updateNote.map((note, index) => (
                            <Card key={index}>
                                <CardHeader
                                    title={note.title}
                                    subheader={note.data}
                                    titleTypographyProps={{variant:'h3'}}
                                    action={
                                        <IconButton onClick={() => handleExpandClick(index)} aria-label="expand" size="small">
                                            {expanded[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    }/>
                                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                                    <CardContent>
                                        {note.context}
                                    </CardContent>
                                </Collapse>
                            </Card>
                        ))
                        }

                    </Stack>
                </Container>
            </Stack>
        </>
    );
}