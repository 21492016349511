import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {Stack, Grid2} from "@mui/material";
import {Typography, Button} from "@mui/material";

// Base
import {TextStringContext} from "./WordCountBase";

// Icon
import CachedIcon from "@mui/icons-material/Cached";
import CardBox from "../../components/CardBox";

export default function WordCountNomal(){
    const navigate = useNavigate();
    const navigateToAdvancedMode = () => {
        navigate('/wordcount/advanced');
    };

    const TextString = useContext(TextStringContext);
    const calculateTextSize = () => { return TextString.length; }
    const calculateTextSizeWithoutSpace = () => { return TextString.replace(/ /g,"").length; }

    return (
        <>
            <Grid2 container
                  spacing={{ xs: 3, md: 2 }}
                  columns={{ xs: 4, sm: 4, md: 12 }}>
                <Grid2 size={4}>
                    <CardBox className="flex-center">
                        <Grid2 container flexDirection="row" columns={3} spacing={3}>
                            <Grid2 size={2}>
                                <Stack className="flex-center" spacing={1}>
                                    <Typography variant="h4" textAlign="center">글자수 세기</Typography>
                                    <Typography variant="body1" textAlign="center">[ Normal Mode ]</Typography>
                                </Stack>
                            </Grid2>
                            <Grid2 size={1}>
                                <Stack className="flex-center" spacing={1}>
                                    <Button variant="contained" startIcon={<CachedIcon />} onClick={navigateToAdvancedMode}>
                                        Mode Change
                                    </Button>
                                </Stack>
                            </Grid2>
                        </Grid2>
                    </CardBox>
                </Grid2>
                <Grid2 size={8}>
                    <CardBox className="flex-center">
                        <Stack spacing={1}>
                            <Typography variant="h6" fontWeight="bold" textAlign="center">공백 포함 : {calculateTextSize()}자</Typography>
                            <Typography variant="h6" fontWeight="bold" textAlign="center">공백 미포함 : {calculateTextSizeWithoutSpace()}자</Typography>
                        </Stack>
                    </CardBox>
                </Grid2>
            </Grid2>
        </>
    )
}

