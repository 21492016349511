import React, {useEffect} from 'react';
import {Box, Stack, Grid2} from "@mui/material";
import {FormControl, Select, InputLabel, MenuItem, SelectChangeEvent} from "@mui/material";
import {Typography} from "@mui/material";

// css
import styles from "../style/ResistanceCalculator.module.css";

// components
import CardBox from "./CardBox";

interface ResistanceColorSelectorProps {
    resetSignal: boolean;
    bandSize: number;
    bandIndex: number
    bandColors: { name: string, color: string }[] ;
    selectedColor: (index: number, colorIndex: number) => void;
}

export const ResistanceColorSelector: React.FC<ResistanceColorSelectorProps> = ({ resetSignal, bandSize, bandIndex, bandColors,  selectedColor }) => {
    const [selectColorIndex, setSelectColorIndex] = React.useState('');

    useEffect(() => {
        setSelectColorIndex('');
    }, [resetSignal]);

    const handleChange = (event: SelectChangeEvent) => {
        const colorIndex = parseInt(event.target.value);
        selectedColor(bandIndex, colorIndex);
        setSelectColorIndex(event.target.value);
    };

    const gridSize = {
        xs: (bandSize === 4 ? 4 : bandIndex !== 4 ? 4 : 8),
        md: (bandSize === 4 ? 3 : bandIndex <= 2 ? 4 : 6)
    };

    return (
        <Grid2 size={gridSize}>
            <CardBox className="flex-center">
                <Stack className="flex-center">
                    <Typography variant="h5" fontWeight="bold" textAlign="center">{bandIndex + 1}번 밴드</Typography>
                    <FormControl fullWidth>
                        <InputLabel id="color-select-label">색상 선택</InputLabel>
                        <Select
                            label="색상 선택"
                            labelId="color-select-label"
                            value={selectColorIndex}
                            onChange={handleChange}>
                            {bandColors.slice(1).map((item, index) => (
                                <MenuItem key={index} value={index}>
                                    <Box style={{width: '100%', display: 'flex'}}>
                                        <Typography variant="body1">{item.name}</Typography>
                                        <Box className="flex-center" justifyContent="flex-end">
                                            <span className={styles.previewCircle} style={{backgroundColor: item.color}}/>
                                        </Box>
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </CardBox>
        </Grid2>
    );
}
