import React from "react";
import {Box, Button, Stack, Typography} from "@mui/material";

// image
import BackgroundImage from "../img/background_nomal.png";
import CardBox from "../components/CardBox";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import {Link, useNavigate} from "react-router-dom";

export default function NotFoundPage(){
    const backgroundStyle = {
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        backgroundPosition: "center center",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(${BackgroundImage})`
    }

    const navigate = useNavigate();
    const navigateToErrorReport = () => navigate("");

    return (
        <>
            <Box sx={{height: "inherit", width: "100vw"}}>
                <Box className="wd-100" sx={backgroundStyle}>
                    <Box className="flex-center">
                        <CardBox>
                            <Stack className="flex-center" spacing={1}>
                                <Typography variant="h1">404 : Not Found</Typography>
                                <Typography variant="h4">원하시는 페이지를 찾을 수 없어요...😢</Typography>
                                <Box/>
                                <Typography variant="h6">다음과 같은 이유로 이 오류가 발생해요!</Typography>
                                <Typography variant="body1">
                                    1. 주소를 잘못 입력 하셨어요.<br/>
                                    2. 서버에 문제가 생겨 페이지를 불러올 수 없어요.<br/>
                                </Typography>
                                <Typography variant="body2">만약, 버그로 예상되신다면 <Link to="/report/error">여기</Link>를 눌러주세요.</Typography>
                                <Box/>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => navigate("/")}>
                                    홈으로 돌아가기
                                </Button>
                            </Stack>
                        </CardBox>
                    </Box>
                </Box>
            </Box>
        </>
    );
}