import React, {useEffect} from 'react';
import {Stack, Button, Container, Typography, Box} from '@mui/material';

// components
import {StandardModal} from "./standardModal";

// Icon
import CheckIcon from '@mui/icons-material/Check';


export const MobileUnsupportedModal = () => {
    const [mobileUnsupportedModalOpen, setMobileUnsupportedModalOpen] = React.useState<boolean>(false);

    const onClose = () => setMobileUnsupportedModalOpen(false);


    const modalContent = (
        <>
            <Container className="flex-center" sx={{minHeight: "10vh"}}>
                <Stack style={{width: "100%"}} spacing={2}>
                    <Typography variant="body1" style={{wordBreak: "keep-all"}} >
                        현재 접속 중인 페이지는 모바일 지원이 완벽하지 않습니다.<br/>
                        따라서, 일부 버그가 있을 수 있다는 점 양해부탁드립니다.
                    </Typography>
                    <Button
                        color="success"
                        variant="contained"
                        onClick={onClose}
                        startIcon={<CheckIcon />}>
                        확인
                    </Button>
                </Stack>
            </Container>
        </>
    )

    // 화면 비율 확인
    useEffect(() => {
        if (window.innerWidth < 768) {
            setMobileUnsupportedModalOpen(true);
        }
    }, []);


    return (
        <>
            <StandardModal
                open={mobileUnsupportedModalOpen}
                onClose={onClose}
                title={"모바일 접속 경고"}
                content={modalContent}
            />
        </>
    )
}