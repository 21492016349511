import React from 'react';
import { Button, Grid2 } from '@mui/material';


// components
import CardBox from "./CardBox"

// Icon
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';;

interface RandomItemCardProps {
    item: string;
    onDelete: () => void;
}

export const RandomItemCard: React.FC<RandomItemCardProps> = ({ item, onDelete }) => {
    return (
        <Grid2 size={{xs: 2, sm: 4, md: 4}}>
            <CardBox style={{height: '100%', justifyContent: "center", alignItems: "center", textAlign: "center" }} variant="outlined">
                <h1 style={{wordBreak: "break-all"}}>{item}</h1>
                <Button
                    variant="contained"
                    color="error"
                    onClick={onDelete}
                    startIcon={<DeleteOutlineIcon />}
                >
                    항목 삭제하기
                </Button>
            </CardBox>
        </Grid2>
    );
};
