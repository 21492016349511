import React from 'react';
import {TextField, Stack, Typography, Container} from '@mui/material';

// components
import {StandardModal} from "./standardModal";

interface RegexCreateTestProps {
    open: boolean;
    onClose: () => void;
    regexString: string;
}

export const RegexCreateTestModal: React.FC<RegexCreateTestProps> = ({ open, onClose, regexString  }) => {
    const [testString, setTestString] = React.useState('');
    const [testResult, setTestResult] = React.useState(false);
    React.useEffect(() => {
        if(open){
            setTestString('');
            testRegex('');
        }
    }, [open])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setTestString(inputValue);
        testRegex(inputValue)
    }
    const testRegex = (testString: string) => {
        const regex = new RegExp(regexString.slice(1, -1));
        console.log(testString, regexString, regex, regex.test(testString));
        setTestResult(regex.test(testString));
    }

    const modalContent = (
        <>
            <Container className="flex-center" sx={{minHeight: "10vh"}}>
                <Stack style={{width: "100%"}} spacing={2}>
                    <Typography variant="h6" textAlign="center">테스트할 문자를 작성해주세요.</Typography>

                    <TextField
                        label="Test 문자"
                        value={testString}
                        onChange={handleChange}
                        placeholder="Ex) Test"
                        helperText={"현재 Test 중인 정규식 : " + regexString}
                        fullWidth
                    />
                    <Typography variant="h6" textAlign="center">Test 결과 : {
                        testResult ?
                            <span style={{color: "#2e7d32"}}>Success</span>
                            :
                            <span style={{color: "#d32f2f"}}>Fail</span>
                    }
                    </Typography>
                </Stack>
            </Container>
        </>
    );

    return (
        <>
            <StandardModal
                open={open}
                onClose={onClose}
                title={"정규식 Test"}
                content={modalContent}
            />
        </>
    );
};