import React from "react";
import {Backdrop, CircularProgress, Stack} from "@mui/material";

interface LoadingProps {
    open: boolean;
    status: string;
    subStatus?: string;
}

export const Loading: React.FC<LoadingProps> = ({ open, status, subStatus }) => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}>
            <Stack style={{alignItems: "center"}}>
                <CircularProgress />
                <h1 style={{marginBottom: 0}}>{status}</h1>
                {subStatus && <p>{subStatus}</p>}
            </Stack>
        </Backdrop>
    );
}