import { createTheme } from '@mui/material/styles';
import '@fontsource/pretendard';

const defaultTheme = createTheme({
    typography: {
        fontFamily: 'Pretendard, Arial, sans-serif',
        h1: {
            fontWeight: 700,
            fontSize: '48px',
            '@media (max-width:600px)': {
                fontSize: '32px',
            },
        },
        h2: {
            fontWeight: 700,
            fontSize: '38px',
            '@media (max-width:600px)': {
                fontSize: '32px',
            },
        },
        h3: {
            fontWeight: 600,
            fontSize: '32px',
            '@media (max-width:600px)': {
                fontSize: '28px',
            },
        },
        h4: {
            fontWeight: 600,
            fontSize: '28px',
            '@media (max-width:600px)': {
                fontSize: '24px',
            },
        },
        h5: {
            fontWeight: 500,
            fontSize: '24px',
            '@media (max-width:600px)': {
                fontSize: '20px',
            },
        },
        h6: {
            fontWeight: 500,
            fontSize: '20px',
            '@media (max-width:600px)': {
                fontSize: '18px',
            },
        },
        body1: {
            fontSize: '16px',
            '@media (max-width:600px)': {
                fontSize: '14px',
            },
        },
        body2: {
            fontSize: '14px',
            '@media (max-width:600px)': {
                fontSize: '12px',
            },
        },
    },
});

export default defaultTheme;
