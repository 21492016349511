import React from 'react';
import {Container, Box, Stack, Button, Grid2, TextField} from '@mui/material';
import {Typography} from '@mui/material';
import { Bounce, toast } from "react-toastify";
import { ToastOptions } from "react-toastify/dist/types";

// components
import { RegexSelector } from "../components/RegexSelector";
import CardBox from "../components/CardBox";

// Modal
import { RegexCreateTestModal } from "../components/modals/regexCreateTestModal";
import { MobileUnsupportedModal } from "../components/modals/mobileUnsupportedModal";

// icon
import AddIcon from "@mui/icons-material/Add";
import CreateIcon from '@mui/icons-material/Create';
import RemoveIcon from '@mui/icons-material/Remove';
import BugReportIcon from '@mui/icons-material/BugReport';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function RegexCreatePage(){
    const [regexList, setRegexList] = React.useState<string[]>([]);
    const [regexResult, setRegexResult] = React.useState<string>("정규식 생성 결과가 여기에 나타납니다.");
    const [regexErrors, setRegexErrors] = React.useState<string[]>([""]);
    const [regexTestModalOpen, setRegexTestModalOpen] = React.useState<boolean>(false);

    const toastOption: ToastOptions = {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
    }

    // 정규식 오류
    const selectorError = (regexIndex: number, errorCode?: string) => {
        console.log(regexIndex, errorCode);
        const newErrors = regexErrors;
        if(errorCode === undefined){
            newErrors[regexIndex] = "";
            setRegexErrors(newErrors);
            return;
        } else {
            newErrors[regexIndex] = errorCode;
            setRegexErrors(newErrors);
            return;
        }
    }

    // 정규식 변경
    const changeRegex = (regexIndex:number, regexString: string) => {
        let newRegexList = regexList;
        newRegexList[regexIndex] = regexString;
        setRegexList(newRegexList);
    }

    // 정규식 생성
    const combineRegex = () => {
        for (let i = 0; i < regexErrors.length; i++) {
            if (regexErrors[i] !== "") {
                toast.error(`"Group ${i + 1}"의 "${regexErrors[i]}"에 오류가 있습니다.`, toastOption);
                return;
            }
        }

        if(regexList.length === 0){
            setRegexResult("정규식 생성 결과가 여기에 나타납니다.");
            toast.error("생성할 정규식이 없습니다.", toastOption);
            return;
        }

        setRegexResult(regexList.join(''));
        toast.info("정규식 생성이 완료되었습니다.", toastOption)
    }

    // 정규식 복사
    const copyRegex = () => {
        if (regexResult === "정규식 생성 결과가 여기에 나타납니다.") {
            toast.error("생성된 정규식이 없습니다.", toastOption);
            return;
        }
        navigator.clipboard.writeText(regexResult);
        toast.info("정규식이 복사되었습니다.", toastOption);
    }

    // 모달 열기
    const testModalOpen = () => {
        if(regexResult === "정규식 생성 결과가 여기에 나타납니다."){
            toast.error("생성된 정규식이 없습니다.", toastOption);
            return;
        }
        setRegexTestModalOpen(true);
    }

    // Group 추가 / 제거
    const addGroup = () => {
        setRegexList(prevState => [...prevState, ".?"])
        toast.info("새로운 Group이 추가되었습니다.", toastOption)
    }

    const deleteGroup = () => {
        setRegexList(prevState => prevState.slice(0, prevState.length - 1));
        toast.info("마지막 Group이 제거되었습니다.", toastOption);
    }

    return (
        <>
            <Container className="main-container">
                <RegexCreateTestModal open={regexTestModalOpen} onClose={()=>setRegexTestModalOpen(false)} regexString={regexResult} />
                <MobileUnsupportedModal/>
                <Stack className="wd-100" spacing={3}>
                    <Grid2 container
                           spacing={{xs: 3, md: 2}}
                           columns={{xs: 4, sm: 4, md: 12}}>
                        <Grid2 size={4}>
                            <CardBox className="flex-center">
                                {/* TODO : Save / Load 기능 추가 */}
                                <Stack className="flex-center" spacing={1}>
                                    <Typography variant="h4" textAlign="center">정규식 생성기</Typography>
                                    <Typography variant="body1" textAlign="center">정규식을 손쉽게 생성 및 테스트해보세요!</Typography>
                                </Stack>
                            </CardBox>
                        </Grid2>
                        <Grid2 size={8}>
                            <CardBox className="flex-center">
                                <Stack className="flex-center" spacing={2}>
                                    <Typography variant="h4" textAlign="center">정규식</Typography>
                                    <TextField disabled label="정규식 생성 결과" value={regexResult} style={{width: "50%"}} />
                                    <Grid2 container
                                           columnSpacing={{xs: 3, md: 2}}
                                            rowSpacing={1}
                                           columns={{xs: 4, sm: 4, md: 12}}>
                                         <Grid2 size={4}>
                                             <Box className="flex-center">
                                                  <Button variant="contained" onClick={combineRegex} startIcon={<CreateIcon />}>
                                                        정규식 생성하기
                                                  </Button>
                                             </Box>
                                         </Grid2>
                                        <Grid2 size={4}>
                                            <Box className="flex-center">
                                                  <Button variant="contained" onClick={copyRegex} startIcon={<ContentCopyIcon />}>
                                                        정규식 복사하기
                                                  </Button>
                                            </Box>
                                         </Grid2>
                                        <Grid2 size={4}>
                                            <Box className="flex-center">
                                                  <Button variant="contained" onClick={testModalOpen} startIcon={<BugReportIcon />}>
                                                        정규식 테스트하기
                                                  </Button>
                                            </Box>
                                         </Grid2>
                                        <Grid2 size={2}/>
                                        <Grid2 size={4}>
                                            <Box className="flex-center">
                                                <Button color="success" variant="contained" onClick={addGroup} startIcon={<AddIcon />}>
                                                    Group 추가하기
                                                </Button>
                                            </Box>
                                        </Grid2>
                                        <Grid2 size={4}>
                                            <Box className="flex-center">
                                                <Button color="error" variant="contained" onClick={deleteGroup} startIcon={<RemoveIcon />}>
                                                    Group 제거하기
                                                </Button>
                                            </Box>
                                        </Grid2>
                                        <Grid2 size={2}/>
                                    </Grid2>
                                </Stack>
                            </CardBox>
                        </Grid2>
                    </Grid2>

                    <Typography variant="h3" fontWeight="bold" textAlign="center">- 정규식 설정 -</Typography>
                    <Grid2 container
                           columns={{xs: 4, sm: 8, md: 12}}
                           columnSpacing={{xs: 1, sm: 2, md: 3}}
                           rowSpacing={2}>

                        {regexList.map((regex, index) => (
                            <RegexSelector selectorIndex={index} selectedRegex={changeRegex} selectorError={selectorError}/>
                        ))}

                    </Grid2>
                </Stack>
            </Container>
        </>
    );
}