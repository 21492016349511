import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './index.css';
import ResponsiveAppBar from "./components/AppBar";
import {Footer} from "./components/Footer";
import MainPage from './Page/MainPage';
import WordCountBase from "./Page/WordCount/WordCountBase";
import NotFoundPage from "./Page/NotFoundPage";
import WordCountAdvanced from "./Page/WordCount/WordCountAdvanced";
import WordCountNomal from "./Page/WordCount/WordCountNomal";
import RandomSelectPage from "./Page/RandomSelectPage";
import ResistanceCalculatorPage from "./Page/ResistanceCalculatorPage";
import RegexCreatePage from "./Page/RegexCreatePage";
import ErrorReportPage from "./Page/Report/ErrorReportPage";
import NewFeatureSuggestPage from "./Page/Report/NewFeatureSuggestPage";
import UpdateNotePage from "./Page/UpdateNotePage";

// Toast
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default class ServiceAppRouter extends Component {
    render():JSX.Element {
        return (
            <BrowserRouter>
                <div style={{minHeight: "100vh", display: "flex", flexDirection: "column"}}>
                    <ResponsiveAppBar/>
                    <div style={{ display: "flex", flexGrow: 1}}>
                        <Routes>
                            {/* Main */}
                            <Route path="/" element={<MainPage/>}/>
                            {/* Report Page */}
                            <Route path="/report/error" element={<ErrorReportPage/>}/>
                            <Route path="/report/newfeature" element={<NewFeatureSuggestPage/>}/>
                            {/* Tools */}
                            <Route path="/wordcount" element={<WordCountBase/>}>
                                <Route index element={<WordCountNomal/>}/>
                                <Route path="nomal" element={<WordCountNomal/>}/>
                                <Route path="advanced" element={<WordCountAdvanced/>}/>
                            </Route>
                            <Route path="/random" element={<RandomSelectPage/>}/>
                            <Route path="/resistance" element={<ResistanceCalculatorPage/>}/>
                            <Route path="/regex" element={<RegexCreatePage/>}/>
                            {/* etc */}
                            <Route path="/update" element={<UpdateNotePage/>}/>
                            <Route path="*" element={<NotFoundPage/>}/>
                        </Routes>
                    </div>
                    <ToastContainer/>
                    <Footer/>
                </div>
            </BrowserRouter>
    );}
}
