import React from 'react';
import {useNavigate} from "react-router-dom";
import {Box, Container, Stack, Grid2, Chip} from "@mui/material";
import {Card, CardHeader, CardContent} from "@mui/material";
import {Typography, Button} from "@mui/material";

// image, css
import BackgroundImage from '../img/background_nomal.png';
import styles from '../style/App.module.css';

// components
import {StandardModal} from "../components/modals/standardModal";

// icon
import AnnouncementIcon from '@mui/icons-material/Announcement';
import LiveHelp from "@mui/icons-material/LiveHelp";
import Update from "@mui/icons-material/Update";


export default function MainPage(){
    const [infoModalOpen, setInfoModalOpen] = React.useState(false);
    const [infoModalTitle, setInfoModalTitle] = React.useState('');
    const [infoModalContent, setInfoModalContent] = React.useState((<>""</>));

    const navigate = useNavigate();
    const navigateToErrorReport = () => navigate("/report/error");
    const navigateToNewFeatureReport = () => navigate("/report/newfeature");
    const navigateToUpdateNote = () => navigate("/update");

    const closeInfoModal = () => {
        setInfoModalOpen(false);
    }

    const openInfoModal = (index: number) => {
        setInfoModalTitle(infoModalList[index].title);
        setInfoModalContent(infoModalList[index].content);
        setInfoModalOpen(true);
    }

    const infoModalList = [
        {
            id: 0,
            title: "글자수 세기",
            content: (
                <>
                    <Stack spacing={1}>
                        <Typography variant="h5" textAlign="center">(간단한 안내 영상을 준비중입니다.)</Typography>
                        <Typography variant="body1" textAlign="center">일반적인 글자수 세기를 원하는 일반 사용자 부터, 정규식을 통한 특정 문자 제외 등의 글자수 세기까지 지원하는 전문 사용자를 위한 기능입니다.</Typography>
                    </Stack>
                </>
            )
        },
        {
            id: 1,
            title: "랜덤 선택기",
            content: (
                <>
                    <Stack spacing={1}>
                        <Typography variant="h5" textAlign="center">(간단한 안내 영상을 준비중입니다.)</Typography>
                        <Typography variant="body1" textAlign="center">매번 새로 입력할 필요 없이, 빠르게 불러올 수 있는 랜덤뽑기 기능입니다. </Typography>
                    </Stack>
                </>
            )
        },
        {
            id: 2,
            title: "저항 색띠 계산기",
            content: (
                <>
                    <Stack spacing={1}>
                        <Typography variant="h5" textAlign="center">(간단한 안내 영상을 준비중입니다.)</Typography>
                        <Typography variant="body1" textAlign="center">저항 색띠 계산기는 저항의 저항값을 색띠로부터 계산해주는 기능입니다.</Typography>
                    </Stack>
                </>
            )
        },
        {
            id: 3,
            title: "정규식 생성기",
            content: (
                <>
                    <Stack spacing={1}>
                        <Typography variant="h5" textAlign="center">(간단한 안내 영상을 준비중입니다.)</Typography>
                        <Typography variant="body1" textAlign="center">정규식 생성기는 사용자가 입력한 문자열을 정규식으로 변환해주는 기능입니다.</Typography>
                    </Stack>
                </>
            )
        }
    ]

    return (
        <>
            <StandardModal
                open={infoModalOpen}
                onClose={()=>closeInfoModal()}
                title={infoModalTitle}
                content={infoModalContent}
            />
            <Stack className="wd-100">
                <Box style={{backgroundColor: "#7f7f7f"}}>
                    <Box className={styles.banner} style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(${BackgroundImage})`}}>
                        <Box display="flex"
                             justifyContent="center"
                             alignItems="center"
                             minHeight="100%">
                            <Typography variant="h1">MakerKSH's Utility Center</Typography>
                        </Box>
                    </Box>
                </Box>
                <Container className="main-container">
                    <Stack spacing={2}>
                        <Card>
                            <CardHeader titleTypographyProps={{variant:'h3'}} title="🤔 MakerKSH's Utility Center는 어떤 사이트인가요?" />
                            <CardContent>
                                <Typography variant="h6">
                                    우선, 방문해줘서 고마워요!<br/>
                                    MakerKSH's Utility Center는 다양한 유틸리티를 모아둔 사이트로, 누구나 무료로 사용할 수 있어요!
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader titleTypographyProps={{variant:'h3'}} title="🧐 어떤 기능이 있나요?" />
                            <CardContent>
                                <Stack spacing={1}>
                                    <Typography variant="h6">
                                        현재 총 4개의 유틸리티가 있으며, 지속해서 열심히 업데이트 중이랍니다!<br/>
                                        아래는 현재 사용할 수 있는 유틸리티와, 제작 계획이 있는 유틸리티에요!<br/>
                                        (각 항목을 클릭하면 자세한 설명을 확인할 수 있어요...!)
                                    </Typography>
                                    <Grid2 container
                                           columns={{xs: 4, sm: 8, md: 12}}
                                           columnSpacing={{xs: 1, sm: 2, md: 3}}
                                           rowSpacing={1}>
                                        {infoModalList.map((item, index) => (
                                            <Grid2 size={{xs: 2, sm: 4, md: 3}} key={item.id}>
                                                <Chip className="flex-center"
                                                    label={item.title}
                                                    onClick={()=>openInfoModal(index)}/>
                                            </Grid2>
                                        ))}
                                    </Grid2>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader titleTypographyProps={{variant:'h3'}} title="😡 버그가 있어요!"/>
                            <CardContent>
                                <Stack spacing={1}>
                                    <Typography variant="h6">
                                        <Typography variant="body2"><del>(제 컴퓨터에서는 잘 굴러가는데요)</del></Typography>
                                        헉... 개발중 발견하지 못한 버그를 찾으셨군요!<br/>
                                        아래 버튼을 눌려 의견을 남겨주시면 최대한 빨리 고쳐볼게요!<br/>
                                    </Typography>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        startIcon={<AnnouncementIcon />}
                                        onClick={navigateToErrorReport}>
                                        버그 신고하기
                                    </Button>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader titleTypographyProps={{variant:'h3'}} title="🥺 추가되었으면 싶은 기능이 있어요..."/>
                            <CardContent>
                                <Stack spacing={1}>
                                    <Typography variant="h6">아래 버튼을 눌러 의견을 검토 후 알려드릴게요!</Typography>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        startIcon={<LiveHelp />}
                                        onClick={navigateToNewFeatureReport}>
                                        기능 제안하기
                                    </Button>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader titleTypographyProps={{variant:'h3'}} title="🤩 어떤 업데이트가 있었나요?"/>
                            <CardContent>
                                <Stack spacing={1}>
                                    <Typography variant="h6">아래 버튼을 누르면 업데이트 내역을 확인하실 수 있어요!</Typography>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        startIcon={<Update />}
                                        onClick={navigateToUpdateNote}>
                                        업데이트 내역 확인하기
                                    </Button>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>
                </Container>
            </Stack>
        </>
    )
}
