import React from 'react';
import {Container, Box, Stack, Grid2} from '@mui/material';
import {Typography, Button} from '@mui/material';

// Toast
import {Bounce, toast} from 'react-toastify';
import {ToastOptions} from "react-toastify/dist/types";

// Hock, Component
import { useRandomItems } from "../hooks/useRandomItems";
import { ItemInput } from '../components/ItemInput';
import { RandomItemCard } from '../components/randomItemCard';
import CardBox from "../components/CardBox";

// Modal
import {StandardModal} from "../components/modals/standardModal";
import {RandomPickSaveModal} from "../components/modals/randomPickSaveModal";
import {RandomPickLoadModal} from "../components/modals/randomPickLoadModal";

// Icon
import DoneIcon from '@mui/icons-material/Done';
import ReplayIcon from '@mui/icons-material/Replay';
import SaveIcon from '@mui/icons-material/Save';
import CachedIcon from "@mui/icons-material/Cached";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import TimerIcon from "@mui/icons-material/Timer";

export default function RandomSelectPage() {
    const { items, addItem, deleteItem, clearItems } = useRandomItems();

    // Modal Open
    const [optionModalOpen, setOptionModalOpen] = React.useState(false);
    const [resultModalOpen, setResultModalOpen] = React.useState(false);
    const [saveModalOpen, setSaveModalOpen] = React.useState(false);
    const [loadModalOpen, setLoadModalOpen] = React.useState(false);

    // Random Result
    const [resultModalTitle, setResultModalTitle] = React.useState('');
    const [resultModalContent, setResultModalContent] = React.useState((<>""</>));
    const [showRandomResult, setShowRandomResult] = React.useState(false);

    // Save Data
    const [saveDataList, setSaveDataList] = React.useState<string[]>([]);

    const toastOption: ToastOptions = {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
    }

    // Check if there are items to pick
    const pickItemOption = () => {
        if (items.length === 0) {
            toast.error('추첨할 항목이 없습니다!', toastOption);
        } else if (items.length === 1) {
            toast.error('추첨할 항목이 1개밖에 없습니다!', toastOption);
        } else {
            setOptionModalOpen(true);
        }
    }

    const readyToPick = (useTImer: boolean) => {
        setShowRandomResult(false);
        setOptionModalOpen(false);
        setResultModalOpen(true);

        if(useTImer) {
            setResultModalTitle("추첨을 시작합니다!");
            setResultModalContent((
                <>
                    <Container className="flex-center" sx={{height: "10vh"}}>
                        <Typography variant="h5" fontWeight="bold">5초 후에 공개됩니다!</Typography>
                    </Container>
                </>
            ));
            let count = 5;
            const interval = setInterval(() => {
                count--;
                setResultModalContent((
                    <>
                        <Container className="flex-center" sx={{height: "10vh"}}>
                            <Typography variant="h5" fontWeight="bold">{count}초 후에 공개됩니다!</Typography>
                        </Container>
                    </>
                ));
                if (count === 0) {
                    pickItemAndShowResult();
                    clearInterval(interval);
                }
            }, 1000)
        } else {
            pickItemAndShowResult();
        }
    }

    const pickItemAndShowResult = () => {
        const randomIndex = Math.floor(Math.random() * items.length);
        const randomItem = items[randomIndex];
        setResultModalTitle("추첨 결과");
        setResultModalContent((
            <>
                <Container className="flex-center" sx={{minHeight: "10vh"}}>
                    <Typography variant="h5" fontWeight="bold">{randomItem} 당첨!</Typography>
                </Container>
            </>
        ));
        setShowRandomResult(true);
    }

    const closeResultModal = () => {
        if (!showRandomResult) toast.error('추첨중에는 창을 끌 수 없어요!', toastOption);
        else setResultModalOpen(false);
    }

    // TODO: localStorage와 관련된 함수는 전부 src/services로 이동
    const loadDataListFromLocalStorage = () => {
        // Get saveDataList
        const keys = Object.keys(localStorage);
        let saveDataList: string[] = [];
        keys.forEach((key) => {
            const data = localStorage.getItem(key);
            if (data === null) return;
            const saveData = JSON.parse(data) as {saveTime: number, saveVersion: string, saveItems: string[]};
            if (saveData.saveTime && saveData.saveVersion && saveData.saveItems) {
                saveDataList.push(key);
            }
        });

        // Sort by saveTime
        saveDataList.sort((a, b) => {
            const aData = JSON.parse(localStorage.getItem(a) as string);
            const bData = JSON.parse(localStorage.getItem(b) as string);
            return bData.saveTime - aData.saveTime;
        });

        setSaveDataList(saveDataList);
    }

    const openLoadModal = () => {
        loadDataListFromLocalStorage();
        setLoadModalOpen(true);
    }

    const optionSelectModalContent= (
        <>
            <Container className="main-container">
                <Stack spacing={2}>
                    <Stack>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<TimerOffIcon/>}
                            onClick={()=>readyToPick(false)}>
                            바로 결과 보기
                        </Button>
                        <Typography variant="body1" textAlign="center">카운트 다운 없이 즉시 추첨합니다!</Typography>
                    </Stack>
                    <Stack>
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<TimerIcon/>}
                            onClick={()=>readyToPick(true)}>
                            카운트 다운 후에 결과 보기
                        </Button>
                        <Typography variant="body1" textAlign="center">카운트 다운(5초) 후에 결과가 공개됩니다!</Typography>
                    </Stack>
                </Stack>
            </Container>
        </>
    )

    return (
        <>
            <Container className="main-container">
                <StandardModal
                    open={optionModalOpen}
                    onClose={()=>setOptionModalOpen(false)}
                    title={"어떻게 추첨할까요?"}
                    content={optionSelectModalContent}
                />
                <StandardModal
                    open={resultModalOpen}
                    onClose={()=>closeResultModal()}
                    title={resultModalTitle}
                    content={resultModalContent}
                />
                <RandomPickSaveModal
                    open={saveModalOpen}
                    onClose={()=>setSaveModalOpen(false)}
                    items={items}
                 />
                <RandomPickLoadModal
                    open={loadModalOpen}
                    onClose={()=>setLoadModalOpen(false)}
                    addItem={addItem}
                    clearItems={clearItems}
                    loadDataList={saveDataList}
                />
                <Stack className="wd-100" spacing={3}>
                    <Grid2 container
                          spacing={{xs: 3, md: 2}}
                          columns={{xs: 4, sm: 4, md: 12}}>
                        <Grid2 size={4}>
                            <CardBox className="flex-center">
                                <Grid2 container flexDirection="row" columns={3} spacing={3}>
                                    <Grid2 size={2}>
                                        <Stack className="flex-center" spacing={1}>
                                            <Typography variant="h4" textAlign="center">랜덤 선택</Typography>
                                            <Typography variant="body1" textAlign="center">당신의 결정을 도와드립니다!</Typography>
                                        </Stack>
                                    </Grid2>
                                    <Grid2 size={1}>
                                        <Stack className="flex-center" spacing={2}>
                                            <Button variant="contained" onClick={() => {setSaveModalOpen(true)}} startIcon={<SaveIcon/>}>
                                                저장하기
                                            </Button>
                                            <Button variant="contained" onClick={() => {openLoadModal()}} startIcon={<ReplayIcon/>}>
                                                불러오기
                                            </Button>
                                        </Stack>
                                    </Grid2>
                                </Grid2>
                            </CardBox>
                        </Grid2>
                        <Grid2 size={4}>
                            <CardBox className="flex-center">
                                <ItemInput onAdd={addItem}/>
                            </CardBox>
                        </Grid2>
                        <Grid2 size={4}>
                            <CardBox className="flex-center">
                                <Stack style={{width: "100%"}} spacing={2}>
                                    <Typography variant="h5" fontWeight="bold" textAlign="center">부가 옵션들</Typography>
                                    <Button variant="contained" color="success" onClick={pickItemOption} startIcon={<DoneIcon/>}>
                                        추첨 시작
                                    </Button>
                                    <Button variant="contained" color="error" onClick={() => clearItems()} startIcon={<CachedIcon/>}>
                                        항목 초기화 하기
                                    </Button>
                                </Stack>
                            </CardBox>
                        </Grid2>
                    </Grid2>

                    <Typography variant="h3" fontWeight="bold" textAlign="center">- 추첨 항목들 -</Typography>
                    <Grid2 container
                          columns={{xs: 4, sm: 8, md: 12}}
                          columnSpacing={{xs: 1, sm: 2, md: 3}}
                          rowSpacing={2}>
                        {items.map((item, index) => (
                            <RandomItemCard
                                key={index}
                                item={item}
                                onDelete={() => deleteItem(index)}
                            />
                        ))}
                    </Grid2>
                </Stack>
            </Container>
        </>
    );
}
