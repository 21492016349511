import React from 'react';
import {Divider, Stack, Box, Grid2, Typography} from "@mui/material";
import {TextField, RadioGroup, Radio, FormControlLabel} from "@mui/material";

// components
import CardBox from "../components/CardBox";


interface RegexSelectorProps {
    selectorIndex: number;
    selectedRegex: (regexIndex: number, regexString: string) => void;
    selectorError: (regexIndex: number, errorCode?: string) => void;
}

export const RegexSelector: React.FC<RegexSelectorProps> = ({ selectorIndex, selectedRegex, selectorError }) => {
    const [allowValue, setAllowValue] = React.useState<string>('.');
    const [allowTimes, setAllowTimes] = React.useState<string>('?');

    // Input 관련 State
    const [allowInput, setAllowInput] = React.useState<string>('');
    const [allowTimesInput, setAllowTimesInput] = React.useState<string>('');

    //
    const combineRegex = (allowValue: string, allowTimes: string) => {
        selectedRegex(selectorIndex, allowValue + allowTimes);
    }

    // input 관련 Handle
    const handleAllowInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setAllowInput(inputValue);

        let combineResult = '[]';
        if(allowValue === 'sm'){
            combineResult = '[' + inputValue + ']';
        } else if(allowValue === '^sm'){
            combineResult = '[^' + inputValue + ']';
        }
        combineRegex(combineResult, allowTimes);
    }

    const handleAllowTimesInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.replaceAll(' ','');
        setAllowTimesInput(inputValue);

        let combineResult = allowTimes;
        if(allowTimes === '{n}' || allowTimes === '{n,}'){
            if(isNaN(Number(inputValue)) || inputValue === ''){
                selectorError(selectorIndex, '적용 횟수 입력');
                return;
            }
            combineResult = combineResult.replace('n', inputValue);
        } else if(allowTimes === '{n,m}'){
            const times = inputValue.split('~')
            if(times.length !== 2 || isNaN(Number(times[0])) || isNaN(Number(times[1]))){
                selectorError(selectorIndex, '적용 횟수 입력');
                return;
            }
            combineResult = '{' + times[0] + ',' + times[1] + '}';
        }

        console.log(combineResult);
        selectorError(selectorIndex);
        combineRegex(allowValue, combineResult);
    }


    // Radio 관련 Handle
    const handleAllowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checkedValue = event.target.value;

        setAllowInput('');
        setAllowValue(checkedValue);
        combineRegex(checkedValue, allowTimes);
    };

    const handleTimesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checkedValue = event.target.value;

        setAllowTimes(checkedValue);

        if(checkedValue === '{n}' || checkedValue === '{n,}' || checkedValue === '{n,m}'){
            setAllowTimesInput('');
            selectorError(selectorIndex, '적용 횟수 입력');
            return;
        } else {
            selectorError(selectorIndex);
        }

        combineRegex(allowValue, checkedValue);
    }

    return (
        <Grid2 size={{xs: 2, sm: 4, md: 4}}>
            <CardBox className="wd-100">
                <Stack spacing={2}>
                    <Typography variant="h5" fontWeight="bold" textAlign="center">Group {selectorIndex + 1}</Typography>
                    <Divider>허용 / 제외 문자</Divider>

                    <RadioGroup onChange={handleAllowChange} value={allowValue}>
                        <Grid2 container
                              spacing={1} columns={2}>
                            <Grid2 size={2}>
                                <FormControlLabel value="." control={<Radio/>} label="모두 허용"/>
                            </Grid2>
                            <Grid2 size={{xs: 2, sm: 1, md: 1}}>
                                <Stack rowGap={{xs: 1, sm: 0, md : 0}}>
                                    <FormControlLabel control={<Radio/>} label="공백만 허용" value="\s"/>
                                    <FormControlLabel control={<Radio/>} label="모든 숫자만 허용" value="\d"/>
                                    <FormControlLabel control={<Radio/>} label="모든 문자만 허용" value="\w"/>
                                    <FormControlLabel control={<Radio/>} label="영어 소문자만 허용" value="[a-z]"/>
                                    <FormControlLabel control={<Radio/>} label="영어 대문자만 허용" value="[A-Z]"/>
                                    <FormControlLabel control={<Radio/>} label="특정 문자만 허용" value="sm"/>
                                </Stack>
                            </Grid2>
                            <Grid2 size={{xs: 2, sm: 1, md: 1}}>
                                <Stack rowGap={{xs: 1, sm: 0, md : 0}}>
                                    <FormControlLabel control={<Radio/>} label="공백만 제외" value="\S"/>
                                    <FormControlLabel control={<Radio/>} label="모든 숫자만 제외" value="\D"/>
                                    <FormControlLabel control={<Radio/>} label="모든 문자만 제외" value="\W"/>
                                    <FormControlLabel control={<Radio/>} label="영어 소문자만 제외" value="[^a-z]"/>
                                    <FormControlLabel control={<Radio/>} label="영어 대문자만 제외" value="[^A-Z]"/>
                                    <FormControlLabel control={<Radio/>} label="특정 문자만 제외" value="^sm"/>
                                </Stack>
                            </Grid2>
                            <Grid2 size={2} display={allowValue === 'sm' || allowValue === '^sm' ? 'inline' : 'none'}>
                                <TextField
                                    label={allowValue === 'sm' ? '허용 문자' : '제외 문자'}
                                    value={allowInput}
                                    onChange={handleAllowInput}
                                    placeholder="Ex) A|B|CD"
                                    helperText="특정 문자(단어)를 |(shift + \)로 구분하여 입력"
                                    fullWidth />
                            </Grid2>
                        </Grid2>
                    </RadioGroup>

                    <Divider>적용 횟수</Divider>
                    <RadioGroup onChange={handleTimesChange} value={allowTimes}>
                        <Stack>
                            <FormControlLabel control={<Radio/>} label="미포함 가능" value="?"/>
                            <FormControlLabel control={<Radio/>} label="0번 이상 (미포함 가능)" value="*"/>
                            <FormControlLabel control={<Radio/>} label="1번 이상" value="+"/>
                            <FormControlLabel control={<Radio/>} label="특정 횟수 (n회)" value="{n}"/>
                            <Box display={allowTimes === '{n}' ? 'inline' : 'none'}>
                                <TextField
                                    label="일치 횟수"
                                    value={allowTimesInput}
                                    onChange={handleAllowTimesInput}
                                    placeholder="Ex) 1"
                                    helperText="숫자만 입력해주세요."
                                    fullWidth/>
                            </Box>
                            <FormControlLabel control={<Radio/>} label="특정 횟수이상 (n회 이상)" value="{n,}"/>
                            <Box display={allowTimes === '{n,}' ? 'inline' : 'none'}>
                                <TextField
                                    label="일치 횟수"
                                    onChange={handleAllowTimesInput}
                                    value={allowTimesInput}
                                    placeholder="Ex) 1"
                                    helperText="숫자만 입력해주세요."
                                    fullWidth/>
                            </Box>
                            <FormControlLabel control={<Radio/>} label="범위로 설정 (n회 이상 m회 미만)" value="{n,m}"/>
                            <Box display={allowTimes === '{n,m}' ? 'inline' : 'none'}>
                                <TextField
                                    label="일치 범위"
                                    onChange={handleAllowTimesInput}
                                    value={allowTimesInput}
                                    placeholder="Ex) 1 ~ 2"
                                    helperText="각 숫자를 ~로 구분하여 입력해주세요."
                                    fullWidth/>
                            </Box>
                        </Stack>
                    </RadioGroup>
                </Stack>
            </CardBox>
        </Grid2>
    );
}