import React, {FC, ReactNode} from 'react';
import { Box } from '@mui/material';

interface CardBoxProps {
    children: ReactNode;
    [key: string]: any; // To allow additional props
}

const CardBox: FC<CardBoxProps> = ({ children, ...props }) => {
    return (
        <Box
            sx={{
                padding: 2,
                boxShadow: 3,
                borderRadius: 2,
                backgroundColor: 'background.paper',
                border: '1px solid #e0e0e0',
                ...props.sx,
            }}
            {...props}
        >
            {children}
        </Box>
    );
};

export default CardBox;
