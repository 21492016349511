import React from 'react';
import {Stack, Container } from '@mui/material';
import {Typography, TextField} from '@mui/material';

// Toast
import {Bounce, toast} from "react-toastify";
import {ToastOptions} from "react-toastify/dist/types";

// components
import {StandardModal} from "./standardModal";

// Icon
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

interface RandomPickSaveProps {
    open: boolean;
    onClose: () => void;
    items: string[];
}

interface SaveRandomPickItemData {
    saveTime: number;
    saveVersion: string;
    saveItems: string[];
}

const toastOption: ToastOptions = {
    position: "top-right",
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    transition: Bounce,
}

export const RandomPickSaveModal: React.FC<RandomPickSaveProps> = ({ open, onClose, items }) => {
    const [saveDataName, setSaveDataName] = React.useState('');
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setSaveDataName(event.target.value);
    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') addItem()
    };



    const addItem = () => {
        if (saveDataName === '') {
            toast.error('저장할 이름을 입력해주세요!', toastOption);
            return;
        } else if (items.length === 0) {
            toast.error('저장할 항목이 없습니다!', toastOption);
            return;
        }

        const saveData : SaveRandomPickItemData = {
            saveTime: new Date().getTime(),
            saveVersion: '1.0.0',
            saveItems: items
        }

        // TODO: localStorage와 관련된 함수는 전부 src/services로 이동
        localStorage.setItem(saveDataName, JSON.stringify(saveData));
        toast.info('저장되었습니다!', toastOption);
        setSaveDataName('');
        onClose();
    };

    const modalContent = (
        <>
            <Container className="flex-center" sx={{minHeight: "10vh"}}>
                <Stack style={{width: "100%"}} spacing={2}>
                    <Stack>
                        <Typography variant="h6" textAlign="center">현재 항목을 뭐라고 저장할까요?</Typography>
                        {/* PC UI */}
                        <Typography variant="body1" textAlign="center" color="#ef5350"
                                    display={{ xs: 'none', sm: 'none', md: 'inline'}}>
                            만약 동일한 이름이 있다면 기존에 저장된 정보는 삭제됩니다!
                        </Typography>
                        {/* Tablet, Mobile UI */}
                        <Typography variant="body1" textAlign="center" color="#ef5350"
                                    display={{xs: 'inline', sm: 'inline', md: 'none'}}>
                            만약 동일한 이름이 있다면<br/> 기존에 저장된 정보는 삭제됩니다!
                        </Typography>
                    </Stack>

                    <TextField
                        label="저장할 이름을 입력해주세요."
                        value={saveDataName}
                        onChange={handleChange}
                        onKeyDown={handleKeyPress}
                        placeholder="Ex) 저녁 메뉴 모음"
                        fullWidth
                    />
                    <Button variant="contained" onClick={addItem} color="success" startIcon={<SaveIcon/>}>
                        저장하기
                    </Button>
                </Stack>
            </Container>
        </>
    )

    return (
        <>
            <StandardModal
                open={open}
                onClose={onClose}
                title={"저장하기"}
                content={modalContent}
            />
        </>
    );
}