import React, {ReactNode} from 'react';
import {Box, Modal} from '@mui/material';
import {Card, CardHeader, CardContent} from "@mui/material";
import {IconButton} from "@mui/material";

// icon
import CloseIcon from '@mui/icons-material/Close';

interface StandardModalProps {
    open: boolean;
    onClose: () => void;
    title: string;
    content: ReactNode;
}

export const StandardModal: React.FC<StandardModalProps> = ({ open, title, content, onClose }) => {
    const modalCenter = {
        position: 'absolute' as 'absolute',
        top: '50%', left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={modalCenter}>
                    <Card sx={{width: {xs: "90vw",md: "50vw"},  position: 'relative'}}>
                        <Box sx={{ position: 'absolute', top: 12, right: 10 }}>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <CardHeader
                            title={title}
                            titleTypographyProps={{variant:'h3', textAlign: 'center'}}/>
                        <CardContent>
                            {content}
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
        </>
    );
};